.hOMEDiv {
  position: absolute;
  width: 77.86%;
  top: 479px;
  right: 12.17%;
  left: 9.98%;
  font-weight: 600;
  display: inline-block;
}
.button {
  position: absolute;
  width: 70.07%;
  top: 585px;
  right: 13.14%;
  left: 16.79%;
  cursor: pointer;
}
.illustration2Icon {
  position: absolute;
  height: 38.27%;
  width: 72.99%;
  top: 11.81%;
  right: 13.38%;
  bottom: 49.91%;
  left: 13.63%;
  max-width: 100%;
  max-height: 100%;
}
.homeDiv {
  position: relative;
  border-radius: 24px;
  background-color: #fcfcfc;
  width: 100%;
  height: 855px;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-family: Poppins;
}
