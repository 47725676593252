.enjoyOurMethodOfLearningF,
.skipIntroA,
.startLearlingEasily {
  position: absolute;
  width: 77.86%;
  right: 11.19%;
  left: 10.95%;
  display: inline-block;
}
.startLearlingEasily {
  top: 547px;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
.enjoyOurMethodOfLearningF,
.skipIntroA {
  top: 599px;
}
.skipIntroA {
  cursor: pointer;
  text-decoration: underline;
  width: 16.3%;
  top: 40px;
  right: 9.73%;
  left: 73.97%;
  font-weight: 600;
  color: inherit;
  text-align: right;
}
.illustration1Icon {
  position: absolute;
  height: 38.27%;
  width: 72.99%;
  top: 16.26%;
  right: 13.63%;
  bottom: 45.47%;
  left: 13.38%;
  max-width: 100%;
  max-height: 100%;
}
.buttonContainedTextAndIco {
  position: absolute;
  top: 747px;
  left: 139px;
  cursor: pointer;
}
.welcomeScreenDiv {
  position: relative;
  border-radius: 24px;
  background-color: #fcfcfc;
  width: 100%;
  height: 855px;
  text-align: center;
  font-size: 14px;
  color: #313131;
  font-family: Poppins;
}
