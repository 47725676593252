.congratulationsItsWorking,
.successDiv {
  position: absolute;
  width: 77.86%;
  display: inline-block;
}
.successDiv {
  top: 547px;
  right: 11.19%;
  left: 10.95%;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
.congratulationsItsWorking {
  top: 599px;
  right: 10.95%;
  left: 11.19%;
}
.button,
.welcomeScreenA {
  position: absolute;
  cursor: pointer;
}
.welcomeScreenA {
  text-decoration: underline;
  width: 77.86%;
  top: 794px;
  right: 10.95%;
  left: 11.19%;
  font-weight: 600;
  color: inherit;
  display: inline-block;
}
.button {
  width: 70.07%;
  top: 703px;
  right: 14.84%;
  left: 15.09%;
}
.illustration3Icon {
  position: absolute;
  height: 41.85%;
  width: 73.11%;
  top: 12.63%;
  right: 13.63%;
  bottom: 45.52%;
  left: 13.26%;
  max-width: 100%;
  max-height: 100%;
}
.successPageDiv {
  position: relative;
  border-radius: 24px;
  background-color: #fcfcfc;
  width: 100%;
  height: 855px;
  text-align: center;
  font-size: 14px;
  color: #313131;
  font-family: Poppins;
}
